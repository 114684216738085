import { ArrowBack, ChevronRight, Folder } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_ROOT_FOLDER_NAME, FILE_TYPES } from "../../../../constants";
import { useGetProjectDetailsMutation, useGetProjectFolderChildrenByIdMutation, useGetRootProjectsMutation, useMoveProjectMutation } from "../../../../services/projects";
import { pushFoldersAndFiles, reCalculateFolderProps, selectFoldersAndFiles } from "../../../../stores/slidebox/slideboxSlice";
import { useSlideboxContext } from "hooks/slideBox/useSlideBoxContext";

const rootView = {
    _id: null,
    name: DEFAULT_ROOT_FOLDER_NAME,
    type: null,
    parent: null,
}

const defaultState = {
  currentFolderId: null, // 3 values: null, root, folderId
  currentFolderView: {
    ...rootView
  },
  children: []
}

const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_STATE': {
        return { ...state, [action.key]: action.value }
      }
      case 'RESET_STATE': {
        return { ...defaultState }
      }
      default:
        return state
    }
  }
  

export function FileAndFolderMove({open, setOpen, ...otherProps}) {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const { state: slideBoxState } = useSlideboxContext();
    const storeDispatch = useDispatch()
    const foldersAndFiles = useSelector(selectFoldersAndFiles)
    const { enqueueSnackbar } = useSnackbar()


    const [getRootProjects] = useGetRootProjectsMutation();
    const [getProjectFolderChildrenById] = useGetProjectFolderChildrenByIdMutation();
    const [getProjectDetails] = useGetProjectDetailsMutation();
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [moveProject] = useMoveProjectMutation();

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index)
    }
 
    const handleCancel = () => {
        setOpen(false)
    }
    const handleMove = async() => {
      const promises = slideBoxState.selectedRows.map((row) => {
        const { _id: id } = row;
        const { ancestors: oldAncestors } =
          foldersAndFiles.find((o) => o._id === id);

        const destination = state.currentFolderView._id;
        return moveProject({
          id,
          destination
        })
          .unwrap()
          .then(({ success, data }) => {
            if (success) {
              const {
                ancestors: newAncestors,
                size: fileSize,
                annotationCount
              } = data;
              const toBeUpdated = [data];
              storeDispatch( pushFoldersAndFiles( toBeUpdated ) );
              storeDispatch(
                reCalculateFolderProps({
                  oldDestinations: [...oldAncestors],
                  newDestinations: [...newAncestors],
                  fileSize,
                  annotationCount
                })
              );
            }
          });
      });
      try {
        await Promise.all(promises);
        enqueueSnackbar('Moved successfully!', { variant: 'sucess' })
        setOpen(false);
      } catch(err) {
        enqueueSnackbar('Failed to move !', { variant: 'error' })
        console.log(err)
      }
    }

    const getRootView = async () => {
        try {
            const {data} = await getRootProjects().unwrap();
            const children = data.filter(
              (item) =>
                item.type === FILE_TYPES.FOLDER &&
                slideBoxState.selectedRows.findIndex(
                  (r) => r._id === item._id
                ) === -1
            );
            dispatch({ type: 'SET_STATE', key: 'currentFolderView', value: rootView })
            dispatch({
              type: 'SET_STATE',
              key: 'children',
              value: children
            });
        } catch(err) {
            dispatch({type: 'SET_STATE', key:'children', value: [] })
        }
    }
    const getFolderChildren = async (id) => {
      try {
          const data = await getProjectFolderChildrenById(id).unwrap();
          dispatch({
            type: 'SET_STATE',
            key: 'children',
            value: data.filter(
              (item) =>
                item.type === FILE_TYPES.FOLDER &&
                slideBoxState.selectedRows.findIndex(
                  (r) => r._id === item._id
                ) === -1
            )
          });
      } catch(err) {
          dispatch({type: 'SET_STATE', key:'children', value: [] })
      }
  }
    const getCurrentViewDetails = async (id) => {
      try {
        const {data} = await getProjectDetails(id).unwrap();
        dispatch({ type: 'SET_STATE', key: 'currentFolderView', value: data })
        await getFolderChildren(id)
      } catch (err) {
        console.log(err)
      }
    }

    const goToFolder = (folder) => {
      dispatch({type: 'SET_STATE', key: 'currentFolderId', value: folder._id})
      dispatch({ type: 'SET_STATE', key: 'currentFolderView', value: folder })
    }

    const goBack = (folderId) => {
      if (folderId === null)
        dispatch({ type: 'SET_STATE', key: 'currentFolderId', value: 'root' })
      else
        dispatch({ type: 'SET_STATE', key: 'currentFolderId', value: folderId })
    }

    useEffect(() => {
        if(state.currentFolderId) {
            if(state.currentFolderId === 'root') {
                getRootView()
            } else {
              getCurrentViewDetails(state.currentFolderId)
            }
        }
    }, [state.currentFolderId])

    useEffect(() => {
      // Handle fetch data when open Modal
      if (open) {
        getRootView()
      }
      //Handle reset state when close Modal
      else {
        dispatch({ type: 'RESET_STATE' })
      }
    }, [open])



    return (
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', height: 435 } }}
        maxWidth="xs"
        open={open}
        {...otherProps}
      >
        <DialogTitle>
          {state.currentFolderView.name !== DEFAULT_ROOT_FOLDER_NAME && (
            <IconButton onClick={() => goBack(state.currentFolderView.parent)}>
              <ArrowBack></ArrowBack>
            </IconButton>
          )}
          {state.currentFolderView.name}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
          <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {state.children.map((folder, index) => (
              <ListItem
                key={index}
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => goToFolder(folder)}
                  >
                    <ChevronRight />
                  </IconButton>
                }
                onClick={() => goToFolder(folder)}
              >
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemIcon>
                    <Folder />
                  </ListItemIcon>
                  <ListItemText primary={folder.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleMove}>Move Here</Button>
        </DialogActions>
      </Dialog>
    )
}