import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateOutlet } from './utils/PrivateOutlet';
import Login from './features/Login/Login';
// import Dashboard from './features/Dashboard/Dashboard';
import Register from './features/Register/Register';
import ResetPassword from './features/ResetPassword/ResetPassword';
import PrivateRoute from './utils/PrivateRoute';
import { ROLES } from './constants';
import Groups from './features/Groups/Groups';
import Analysis from './features/Analysis/Analysis';
import { SlideboxOutlet } from './features/Slidebox';
import SlideViewer from './features/SlideViewer/SlideViewer';
import { useAuth } from './hooks/useAuth';
import './styles/index.scss';
import Verify from 'features/Verify/Verify';
import { useSlideBox } from 'hooks/slideBox/useSlideBox';
import LoadingOverlay from 'components/Layout/LoadingOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowLoading } from 'stores/app/appSlice';
import SlideboxViewer from 'features/Slidebox/SlideboxViewer';
import DashboardV2 from './v2/features/Dashboard';
import UserDetail from 'v2/features/Dashboard/UserDetail';
import Profile from 'v2/features/Profile';
import instance from 'services/axios';
import { removeCredentials, setCredentials } from 'stores/auth/authSlice';

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth();

  const indexRedirectTo = useMemo(() => {
    if (user) {
      if (user.role === 'superadmin') return '/dashboard';
      else return '/slidebox';
    }
  }, [user]);

  const showAppLoading = useSelector(selectShowLoading);

  const {
    handleSetFilters,
    handleSetSlideBoxPage,
    handleSetSlideBoxRowsPerPage
  } = useSlideBox();

  const loadFilters = useCallback(() => {
    // Load filters from local Storage
    let filters = {
      keyword: '',
      fileType: '',
      ownerEmail: '',
      updatedAt: ''
    };
    const localStorageFiltersVal = localStorage.getItem('slideboxFilter');
    if (localStorageFiltersVal) {
      try {
        filters = JSON.parse(localStorageFiltersVal);
      } catch (err) {
        filters = {
          keyword: '',
          fileType: '',
          ownerEmail: '',
          updatedAt: ''
        };
        console.log(err);
      }
    }
    handleSetFilters(filters);
  }, [handleSetFilters]);

  const loadSlideboxPage = useCallback(() => {
    const localStoragePageVal = localStorage.getItem('slideboxPage')
      ? Number(localStorage.getItem('slideboxPage'))
      : 0;
    handleSetSlideBoxPage(localStoragePageVal);
  }, [handleSetSlideBoxPage]);

  const loadSlideboxRowsPerPage = useCallback(() => {
    const localStorageRowsPerPageVal = localStorage.getItem(
      'slideboxRowsPerPage'
    )
      ? Number(localStorage.getItem('slideboxRowsPerPage'))
      : 10;
    handleSetSlideBoxRowsPerPage(localStorageRowsPerPageVal);
  }, [handleSetSlideBoxRowsPerPage]);

  const fetchCurrentUser = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) return;
    try {
      const data = await instance.get('/auth/me').then((res) => res?.data);
      if (data?.success) {
        dispatch(
          setCredentials({
            user: data?.data,
            token
          })
        );
      } else {
        dispatch(removeCredentials());
      }
    } catch (error) {
      console.log(error);
      dispatch(removeCredentials());
    }
  }, [dispatch]);

  const fetchData = async () => {
    await fetchCurrentUser();
    loadFilters();
    loadSlideboxPage();
    loadSlideboxRowsPerPage();
    setIsLoaded(true);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showAppLoading && <LoadingOverlay />}
      {isLoaded && (
        <Routes>
          <Route path="/verify" element={<Verify />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<PrivateOutlet />}>
            <Route index element={<Navigate to={indexRedirectTo || ''} />} />
            <Route path="/slidebox" element={<SlideboxOutlet />}>
              <Route index element={<SlideboxViewer />} />
            </Route>
            <Route path="/slideViewer/:id" element={<SlideViewer />} />
            <Route path="/slideViewer/mcd/:id" element={<SlideViewer />} />
            <Route path="/groups">
              <Route index element={<Groups />} />
              <Route path="/groups/:id" element={<Groups />} />
            </Route>
            <Route path="/analysis" element={<Analysis />} />
            <Route path="/dashboard">
              <Route
                index
                element={
                  <PrivateRoute roles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}>
                    {/* <Dashboard /> */}
                    <DashboardV2 />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard/users/:id"
                element={
                  <PrivateRoute roles={[ROLES.SUPER_ADMIN, ROLES.ADMIN]}>
                    <UserDetail />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/profile">
              <Route index element={<Profile />} />
            </Route>
          </Route>
        </Routes>
      )}
    </>
  );
}

export default App;
