import {
  Card,
  CircularProgress,
  Container,
  Tab,
  Tabs,
  alpha
} from '@mui/material';

import Label from 'v2/components/label';
import { ROLES } from '../../../constants';
import TabPanel from 'components/TabPanel/Tab';
import UserManagement from './UserManagement';
import PendingInvitations from './PendingInvitations';
import Organizations from './Organizations';
import {
  DashboardContextProvider,
  useDashboardContext
} from 'v2/contexts/DashboardContext';
import { useAuth } from 'hooks/useAuth';

const Dashboard = () => {
  return (
    <DashboardContextProvider>
      <Container
        maxWidth={false}
        sx={{
          height: '100%',
          py: 3
        }}
      >
        <Card
          sx={{
            p: 0,
            height: '100%',
            borderRadius: 2
          }}
        >
          <DashboardTab />
          <DashboardTabPanel />
        </Card>
      </Container>
    </DashboardContextProvider>
  );
};

const DashboardTab = () => {
  const {
    tab,
    handleTabChange,
    isLoadingMembers,
    users,
    isLoadingPendingInvitations,
    pendingInvitations,
    organizations,
    isLoadingOrganizations
  } = useDashboardContext();
  const {
    user: { role }
  } = useAuth();

  return (
    <Tabs
      value={tab}
      onChange={(e, newValue) => handleTabChange(newValue)}
      sx={{
        px: 2.5,
        boxShadow: (theme) =>
          `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
      }}
    >
      <Tab
        iconPosition="end"
        value={0}
        label="Managed By Me"
        sx={{
          textTransform: 'none'
        }}
        icon={
          <Label variant="filled" color="success">
            {isLoadingMembers ? (
              <CircularProgress color="inherit" size={12} />
            ) : (
              users?.length - 1 //! Exclude the current user
            )}
          </Label>
        }
      />
      <Tab
        iconPosition="end"
        value={1}
        label="Pending Invitations"
        sx={{
          textTransform: 'none'
        }}
        icon={
          <Label variant="filled" color="warning">
            {isLoadingPendingInvitations ? (
              <CircularProgress color="inherit" size={12} />
            ) : (
              pendingInvitations?.length
            )}
          </Label>
        }
      />
      {role === ROLES.SUPER_ADMIN && (
        <Tab
          iconPosition="end"
          value={2}
          label="Organizations"
          sx={{
            mt: 0.5,
            minHeight: 'auto',
            textTransform: 'none'
          }}
          icon={
            <Label variant="filled" color="primary">
              {isLoadingOrganizations ? (
                <CircularProgress color="inherit" size={12} />
              ) : (
                organizations?.length
              )}
            </Label>
          }
        />
      )}
    </Tabs>
  );
};

const DashboardTabPanel = () => {
  const {
    tab,
    pendingInvitations,
    organizations,
    onRefresh,
    refetchOrganizations,
    users,
    onInviteSuccess
  } = useDashboardContext();
  const {
    user: { role }
  } = useAuth();

  return (
    <>
      <TabPanel value={tab} index={0}>
        <UserManagement
          users={users}
          onInviteSuccess={onInviteSuccess}
          onRefresh={onRefresh}
          organizations={organizations}
          isShowOrganization={true}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <PendingInvitations
          pendingInvitations={pendingInvitations}
          onRefresh={onRefresh}
        />
      </TabPanel>
      {role === ROLES.SUPER_ADMIN && (
        <TabPanel value={tab} index={2}>
          <Organizations
            organizations={organizations}
            onRefresh={onRefresh}
            onCreateSuccess={() => {
              refetchOrganizations();
            }}
          />
        </TabPanel>
      )}
    </>
  );
};

export default Dashboard;
