import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { LoadingButton } from '@mui/lab';
import {
  Badge,
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from '@mui/material';
import { useDeleteOrganizationMutation } from 'services/organizations';
import { IOrganization } from 'types/organization';
import { ConfirmDialog } from 'v2/components/custom-dialog';
import { usePopover } from 'v2/components/custom-popover';
import Iconify from 'v2/components/iconify';
import Label from 'v2/components/label';
import { useDashboardContext } from 'v2/contexts/DashboardContext';
import { useBoolean } from 'v2/hooks/use-boolean';
import { getOrganizationStatusColor } from 'v2/utils/organization';
import OrganizationCreateForm from './OrganizationCreateForm';
import OrganizationMemberDialog from './OrganizationMemberDialog';

type Props = {
  row: IOrganization;
  onRefresh: VoidFunction;
};

const OrganizationTableRow = ({ row, onRefresh }: Props) => {
  const {
    name,
    description,
    email,
    address,
    isActive,
    phone,
    collaboratorLimit
  } = row;
  const { users } = useDashboardContext();
  const [deleteOrganization, { isLoading }] = useDeleteOrganizationMutation();
  const quickEdit = useBoolean();

  const popover = usePopover();

  const confirm = useBoolean();

  const viewMembers = useBoolean();

  const onDeleteRow = async () => {
    try {
      await deleteOrganization({
        id: row._id
      });
      onRefresh();
      confirm.onFalse();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{name}</TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{address}</TableCell>
        <TableCell align="center">{phone}</TableCell>
        <TableCell align="center">{collaboratorLimit}</TableCell>
        <TableCell align="center">
          <Label variant="soft" color={getOrganizationStatusColor(isActive)}>
            {isActive ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Members" placement="top" arrow>
            <IconButton
              color={viewMembers.value ? 'inherit' : 'default'}
              onClick={viewMembers.onTrue}
            >
              <Badge
                badgeContent={
                  users?.filter((user) => user.organization._id === row._id)
                    .length
                }
                color="info"
              >
                <Iconify icon="mdi:account-group" />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? 'inherit' : 'default'}
              onClick={quickEdit.onTrue}
            >
              <ModeEditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              color="error"
              onClick={confirm.onTrue}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <OrganizationCreateForm
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
        onCallback={onRefresh}
        currentOrg={row}
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="error"
            onClick={onDeleteRow}
          >
            Delete
          </LoadingButton>
        }
      />

      {viewMembers.value && (
        <OrganizationMemberDialog
          organization={row}
          onRefresh={() => {}}
          onClose={viewMembers.onFalse}
        />
      )}
    </>
  );
};

export default OrganizationTableRow;
