import {
  AddToQueue, DeleteOutline, Info as InfoIcon,
  OpenWith as OpenWithIcon
} from '@mui/icons-material';
import { Box, Button, Divider, Tooltip } from '@mui/material';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { useAuth } from 'hooks/useAuth';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FILE_TYPES, ROLES } from '../../../../../constants';
import ShareButton from '../ShareButton';

const EnhancedTableToolbar = ( { onClickDeleteIcon } ) => {
  const {
    state,
    dispatch,
    isSelected,
    isSingleSelected,
    firstSelectedElement,
    isOwner
  } = useSlideboxContext();

  const {
    user: { role }
  } = useAuth();

  const navigate = useNavigate();

  const openWithViewer = useCallback( () => {
    navigate( `/slideViewer/${ state.selectedRows[ 0 ] }`, { replace: true } );
  }, [ state.selectedRows, navigate ] );

  const openDetailDrawer = () => {
    dispatch( { type: 'SET_STATE', key: 'openDetail', value: true } );
  };

  const isSingleFileSelect = useMemo(
    () => isSingleSelected && firstSelectedElement.type === FILE_TYPES.FILE,
    [ isSingleSelected, firstSelectedElement ]
  );

  const isMultipleSelectedHasFile = useMemo(
    () => state.selectedRows.some( ( row ) => row.type === FILE_TYPES.FILE ),
    [ state.selectedRows ]
  );

  const ActionButtonComponent = useMemo( () => {
    if ( !isOwner || isMultipleSelectedHasFile ) return null;

    const handleMove = () => {
      dispatch( { type: 'SET_STATE', key: 'openMoveFolder', value: true } );
    };

    if ( isSingleFileSelect ) {
      return (
        <Box style={{ marginLeft: '4px' }}>
          <Tooltip title="Open with viewer">
            <Button variant="outlined" onClick={openWithViewer}>
              <AddToQueue />
            </Button>
          </Tooltip>
        </Box>
      );
    }
    return (
      <Box style={{ marginLeft: '4px' }}>
        <Tooltip title="Move">
          <Button variant="outlined" onClick={handleMove}>
            <OpenWithIcon />
          </Button>
        </Tooltip>
      </Box>
    );
  }, [
    isOwner,
    isMultipleSelectedHasFile,
    isSingleFileSelect,
    openWithViewer,
    dispatch
  ] );
  const noFolderSelected = () => {
    let folderExists = false;
    state.selectedRows.forEach( ( row ) => {
      if ( row.type === 'folder' ) {
        folderExists = true;
      }
    } );
    return !folderExists;
  };

  const canShowShareButton = ( role, selectedRows ) => {
    if ( !role || !selectedRows?.length ) return false;

    const isRegularUser = role === ROLES.ADMIN || role === ROLES.USER;
    const isSuperAdmin = role === ROLES.SUPER_ADMIN;

    const isShareable = selectedRows.every( row => {
      if ( !row ) return false;
      return (
        row.isShareable && row.type !== FILE_TYPES.MCD //TODO Add share functionality for MCD Files
      );
    } );

    return (
      noFolderSelected() &&
      isShareable &&
      ( isRegularUser || ( isSuperAdmin && selectedRows.length < 2 ) )
    );
  };

  return (
    isSelected && (
      <>
        {canShowShareButton( role, state.selectedRows ) && (
          <ShareButton
            entities={state.selectedRows}
            calledFrom="tableTop"
          />
        )}
        {isOwner && (
          <Box style={{ marginLeft: '4px' }}>
            <Tooltip title="Delete">
              <Button variant="outlined" onClick={() => onClickDeleteIcon()}>
                <DeleteOutline />
              </Button>
            </Tooltip>
          </Box>
        )}

        {ActionButtonComponent}

        {isSingleSelected &&
          firstSelectedElement.type !== FILE_TYPES.FILE &&
          firstSelectedElement.type !== FILE_TYPES.MCD &&
          (
            <>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <Box style={{ marginLeft: '4px' }}>
                <Tooltip title="Info">
                  <Button variant="outlined" onClick={openDetailDrawer}>
                    <InfoIcon />
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
      </>
    )
  );
};

EnhancedTableToolbar.propTypes = {
  onClickShareIcon: PropTypes.func.isRequired,
  onClickDeleteIcon: PropTypes.func.isRequired
};
export default EnhancedTableToolbar;
