import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { MCDAreaDataMock } from '__mocks__/areaData.mock';
import { SetStateAction, useState } from 'react';
import theme from 'theme';

export type MCDAreaData = {
    channel: string;
    CV: number; //? Coefficient of Variation (%)
    integral: number;
    label: string;
    max: number;
    mean: number;
    min: number;
};

type MCDAreaDataPopUpProps = {
    data: MCDAreaData[];
};

const rowsPerPageOptions = [ 5, 10, 25 ];

const MCDAreaDataPopUp = ({ data = MCDAreaDataMock }: MCDAreaDataPopUpProps) => {
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(10);

    const handleChangePage = (event: any, newPage: SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Container maxWidth="xl">
            { data.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Channel</TableCell>
                                <TableCell>Label</TableCell>
                                <TableCell>Min</TableCell>
                                <TableCell>Max</TableCell>
                                <TableCell>Integral</TableCell>
                                <TableCell>Mean</TableCell>
                                <TableCell>CV (%)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.channel}</TableCell>
                                    <TableCell>{row.label}</TableCell>
                                    <TableCell>{row.min}</TableCell>
                                    <TableCell>{row.max}</TableCell>
                                    <TableCell>{row.integral}</TableCell>
                                    <TableCell>{row.mean}</TableCell>
                                    <TableCell>{row.CV}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={ rowsPerPageOptions }
                        component="div"
                        count={ data.length }
                        rowsPerPage={ rowsPerPage }
                        page={ page }
                        onPageChange={ handleChangePage }
                        onRowsPerPageChange={ handleChangeRowsPerPage }
                    />
                </TableContainer>
            ) : (
                <Box
                    sx={ { textAlign: 'center', color: theme.palette.grey[ 500 ] } }
                    mt={ 1 }
                >
                    No analysis data found
                </Box>
            ) }
        </Container>
    );
};

export default MCDAreaDataPopUp;