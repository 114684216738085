import React, { useState } from 'react';
import { Box } from '@mui/material';
import { GroupsContextProvider } from '../../contexts/GroupsContext';
import GroupDetail from './components/GroupDetail';
import GroupSubDrawer from './components/GroupSubDrawer';
import { useListGroupsQuery } from 'services/groups';
import { useParams } from 'react-router-dom';

const Groups = () => {
  const { id } = useParams();
  const [keyword, setKeyword] = useState('');
  const { data: response } = useListGroupsQuery({
    limit: 50
  });

  return (
    <GroupsContextProvider groups={response} groupId={id}>
      <Box sx={{ display: 'flex', position: 'relative' }}>
        <GroupSubDrawer keyword={keyword} setKeyword={setKeyword} />
        <Box
          component="main"
          sx={{ flexGrow: 1, zIndex: 3, position: 'relative' }}
        >
          <GroupDetail />
        </Box>
      </Box>
    </GroupsContextProvider>
  );
};

export default Groups;
