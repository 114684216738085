import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: localStorage.getItem('token') || null,
  organization: null
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload: { user, token } }) => {
      localStorage.setItem('token', token);
      state.user = user;
      state.token = token;
      state.organization = user.organization;
    },
    removeCredentials: (state) => {
      localStorage.removeItem('token');
      state.user = null;
      state.token = null;
      state.organization = null;
    },
    updateUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    }
  }
});

export const { setCredentials, removeCredentials, updateUser } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectOrganization = (state) => state.auth.organization;
export const selectToken = (state) => state.auth.token;
