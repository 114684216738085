// src/types/MCDOptionTypes.ts
export enum MCDLayerColor {
    RED = 'red',
    GREEN = 'green',
    BLUE = 'blue',
    YELLOW = 'yellow',
    CYAN = 'cyan',
    MAGENTA = 'magenta',
    WHITE = 'white',
    BLACK = 'black',
}

export const MCDLayerColorMapToRGB: Record<MCDLayerColor, { r: number, g: number, b: number; }> = {
    [ MCDLayerColor.RED ]: { r: 255, g: 0, b: 0 },
    [ MCDLayerColor.GREEN ]: { r: 0, g: 255, b: 0 },
    [ MCDLayerColor.BLUE ]: { r: 0, g: 0, b: 255 },
    [ MCDLayerColor.YELLOW ]: { r: 255, g: 255, b: 0 },
    [ MCDLayerColor.CYAN ]: { r: 0, g: 255, b: 255 },
    [ MCDLayerColor.MAGENTA ]: { r: 255, g: 0, b: 255 },
    [ MCDLayerColor.WHITE ]: { r: 255, g: 255, b: 255 },
    [ MCDLayerColor.BLACK ]: { r: 0, g: 0, b: 0 },
};

export type MCDLayerType = {
    selected: boolean;
    channel: string;
    label: string;
    color: MCDLayerColor;
    urlImage: string;
    originalIndex: number;
};

export interface SlideViewerState {
    // ... other state properties
    MCDLayers: MCDLayerType[];
}

export type SlideViewerAction =
    | { type: 'SET_MCD_SLIDES'; payload: MCDSlide[]; }
    | { type: 'SELECT_MCD_SLIDE'; payload: string; }
    | { type: 'DESELECT_ALL_MCD_LAYERS'; }
    | { type: 'SELECT_ALL_MCD_LAYERS'; }
    | { type: 'SET_MCD_LAYERS'; payload: MCDLayerType[]; }
    | { type: 'TOGGLE_MCD_LAYER_SELECT'; payload: number; }
    | { type: 'UPDATE_MCD_LAYER_COLOR'; payload: { index: number; color: MCDLayerColor; }; };

// Type definition for Layer
export interface MCDLayer {
    id: number;
    index: number;
    name: string;
    description?: string;
    width_um?: number;
    height_um?: number;
    color?: string;
    toggled: boolean;
    gcsFileName?: string; // Google Cloud Storage file name
}

// Type definition for Slide
export interface MCDSlide {
    _id: number;
    name: string;
    height: number;
    width: number;
    description: string | null;
    gcsFileName: string | null;
    acquisitions: MCDSlideAcquisition[];
    selected?: boolean;
}

export interface MCDFile {
    id: number;
    name: string;
    project: number;
    gcsFileName: string;
    created_at: Date;
    created_by: CreatedBy;
    size: number;
    tags: any[];
    case_identifiers: any[];
    slides: MCDSlide[];
}

export interface CreatedBy {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}

export interface MCDCreatorProfile {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}

export interface MCDSlideAcquisition {
    id: number;
    channels_count: number;
    channels_labels: string[];
    channels_masses: number[];
    channels_names: string[];
    description: string;
    height_um: number;
    width_um: number;
    selected?: boolean;
    image_path: string;
}
