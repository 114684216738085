import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useState, useContext } from 'react';
import AnnotationDetails from './AnnotationDetails/AnnotationDetails';
import MCDLayers from './MCDLayers';
import MCDROISelector from './MCDROISelector';
import MCDSlidesSelector from './MCDSlidesSelector';
import SlideCaseIdentifiers from './SlideCaseIdentifiers';
import SlideDetails from './SlideDetails';
import SlideTags from './SlideTags';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { useGetFileByIdMutation } from 'services/slides';

const drawerWidth = 400;

const openedMixin = ( theme ) => ( {
  width: drawerWidth,
  transition: theme.transitions.create( 'width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  } ),
  overflowX: 'hidden',
  '& .slide-manager__header-text': {
    display: 'block'
  }
} );

const closedMixin = ( theme ) => ( {
  transition: theme.transitions.create( 'width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  } ),
  overflowX: 'hidden',
  width: `calc(${ theme.spacing( 7 ) } + 1px)`,
  [ theme.breakpoints.up( 'sm' ) ]: {
    width: `calc(${ theme.spacing( 8 ) } + 1px)`
  },
  '& .slide-manager__header-text': {
    display: 'none'
  }
} );

const DrawerHeader = styled( 'div' )( ( { theme } ) => ( {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing( 0, 1 ),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
} ) );

const Drawer = styled( MuiDrawer,
  { shouldForwardProp: ( prop ) => prop !== 'open' }
)( ( { theme, open } ) => ( {
  '& .MuiPaper-root': {
    position: 'static'
  },

  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...( open && {
    ...openedMixin( theme ),
    '& .MuiDrawer-paper': openedMixin( theme )
  } ),
  ...( !open && {
    ...closedMixin( theme ),
    '& .MuiDrawer-paper': closedMixin( theme )
  } )
} ) );


const SlideManager = ( { slideId, isMCD } ) => {
  const [ open, setOpen ] = useState( true );
  const [ state , dispatch ] = useContext( SlideViewerContext );
  const [ getFileById ] = useGetFileByIdMutation();

  const handleDrawerOpen = () => {
    setOpen( true );
  };

  const handleDrawerClose = () => {
    setOpen( false );
  };

  const refetchGroup = async ( ) => {
    const { data } = await getFileById( slideId ).unwrap();
    dispatch( {
      type: 'UPDATE_GROUP',
      payload: data.groups
    } );
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Typography
          className="slide-manager__header-text"
          sx={{ fontWeight: 'bold' }}
        >
          SLIDE MANAGER
        </Typography>
        <IconButton
          onClick={() => ( open ? handleDrawerClose() : handleDrawerOpen() )}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <SlideDetails hidden={!open} handleDrawerOpen={handleDrawerOpen} />
      <Divider />
      <SlideTags />
      <Divider />
      <SlideCaseIdentifiers />
      <Divider />
      <AnnotationDetails hidden={!open} handleDrawerOpen={handleDrawerOpen} refetchGroup={refetchGroup} isMCDFile={isMCD} />
      {/* <SlideAttachments /> <Divider /> */}
      <Divider />
      {isMCD && <MCDSlidesSelector />}
      <Divider />
      {isMCD && state.MCDROIs?.length > 0 && <MCDROISelector />}
      <Divider />
      {isMCD && state.MCDLayers?.length > 0 && <MCDLayers setOptions={() => alert( 'Color changed' )} />}
      <Divider />
      {/* <SlideReviewLog hidden={!open} handleDrawerOpen={handleDrawerOpen} /> */}
      {/* <Divider /> */}
      {/* TODO: Hide for now */}
      {/* <Divider />
      <SlideAnalysisResult hidden={!open} />
      <Divider /> */}
    </Drawer>
  );
};
export default SlideManager;
