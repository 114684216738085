import { Box, Button, Tooltip } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const RepaintButton = ({ onClick, disabled = true }) => {
  if (disabled) return null;
  
  return (
    <Box style={{ marginLeft: '4px' }}>
      <Tooltip title="Refresh layers and reapply filters">
        <Button 
          variant="outlined" 
          onClick={onClick}
        >
          <AutorenewIcon 
            fontSize="small"
            sx={{ marginRight: '4px' }}
          />
          Reload Layers
        </Button>
      </Tooltip>
    </Box>
  );
};

export default RepaintButton;