import {
  FILE_TYPES,
  MANAGED_BY_ME_FOLDER_NAME,
  SHARED_WITH_ME_FOLDER_NAME
} from '../constants';
import { api } from './api';

export const projectAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllProjects: build.mutation({
      query: () => ({ url: '/projects/all' }),
      transformResponse: (response, meta, arg) => {
        const searchData = response.data;
        return {
          success: response.success,
          data: searchData.sort(
            (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
          )
        };
      }
    }),
    getProjectNames: build.query({
      query: (params) => ({ url: '/projects/names', params }),
      transformResponse: (response) => response.data
    }),
    getRootProjects: build.mutation({
      query: () => ({ url: '/projects/root' }),
      transformResponse: (response, meta, arg) => {
        const searchData = response.data;
        return {
          success: response.success,
          data: searchData.sort(
            (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
          )
        };
      }
    }),
    getSharedWithMeProjects: build.mutation({
      query: () => ({ url: 'projects/sharedProjects' }),
      transformResponse: (response, meta, arg) => {
        return {
          success: response.success,
          data: response.data
            .map((d) => ({
              ...d,
              parent: SHARED_WITH_ME_FOLDER_NAME
            }))
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        };
      }
    }),
    getAllManagedProjects: build.mutation({
      query: () => ({ url: '/projects/managedProjects' }),
      transformResponse: (response, meta, arg) => {
        return {
          success: response.success,
          data: response.data
            .map((d) => ({
              ...d,
              parent: MANAGED_BY_ME_FOLDER_NAME
            }))
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        };
      }
    }),
    getUserRootProjects: build.mutation({
      query: (userId) => ({ url: `/projects/${userId}/root` }),
      transformResponse: (response, meta, arg) => {
        const searchData = response.data;
        return {
          success: response.success,
          data: searchData.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        };
      }
    }),
    createProject: build.mutation({
      query: (body) => ({
        url: `/projects/`,
        method: 'POST',
        body
      })
      // invalidatesTags: [{ type: 'Uploads', id: 'LIST' }]
    }),
    getProjectDetails: build.mutation({
      query: (id) => ({
        url: `/projects/${id}`,
        method: 'GET'
      })
    }),
    getProjectFolderChildrenById: build.mutation({
      query: (id) => ({
        url: `/projects/${id}/children`,
        method: 'GET'
      }),
      transformResponse: (response, meta, arg) => {
        let result = [];
        if (response.success) {
          const { slides, projects, multilayers } = response.data;
          result.push(
            ...projects,
            ...multilayers.map( ( mcd ) => ( {
              ...mcd,
              _id: mcd.id,
              createdBy: mcd.created_by,
              createdAt: mcd.created_at,
              updatedAt: mcd.updated_at || mcd.created_at,
              isRemovable: true,
              isRenamable: false,
              isShareable: false,
              parent: mcd.project,
              size: mcd.size || 0,
              type: FILE_TYPES.MCD,
            })),
            ...slides.map((slide) => ({
              ...slide,
              type: FILE_TYPES.FILE,
              parent: slide.project,
              isRenamable: true,
              isRemovable: true,
              isShareable: true
            }))
          );
        }
        return result.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
      }
    }),
    deleteProject: build.mutation({
      query: (id) => ({
        url: `/projects/${id}`,
        method: 'DELETE'
      })
    }),
    moveProject: build.mutation({
      query: ({ id, destination }) => ({
        url: `/projects/${id}/move`,
        method: 'POST',
        body: {
          destination
        }
      })
    }),
    getProjectActivityLogs: build.mutation({
      query: (id) => ({
        url: `/projects/${id}/activityLogs`,
        method: 'GET'
      })
    }),
    shareProject: build.mutation({
      query: ({ id, emails }) => ({
        url: `/projects/${id}/share`,
        method: 'POST',
        body: { emails }
      })
    }),
    search: build.mutation({
      query: (keyword) => ({
        url: `/projects/search?keyword=${keyword}`,
        method: 'GET'
      }),
      transformResponse: (response, meta, arg) => {
        const searchData = response.data;
        return {
          success: response.success,
          data: searchData.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        };
      }
    }),
    renameProject: build.mutation({
      query: ({ id, body }) => ({
        url: `/projects/${id}`,
        method: 'PATCH',
        body
      })
    }),
    updateProject: build.mutation({
      query: ({ id, body }) => ({
        url: `/projects/${id}`,
        method: 'PATCH',
        body
      })
    })
  })
});

export const {
  useGetAllProjectsMutation,
  useGetRootProjectsMutation,
  useGetSharedWithMeProjectsMutation,
  useGetUserRootProjectsMutation,
  useGetProjectDetailsMutation,
  useCreateProjectMutation,
  useGetProjectFolderChildrenByIdMutation,
  useDeleteProjectMutation,
  useGetProjectActivityLogsMutation,
  useMoveProjectMutation,
  useGetAllManagedProjectsMutation,
  useShareProjectMutation,
  useSearchMutation,
  useRenameProjectMutation,
  useUpdateProjectMutation,
  useGetProjectNamesQuery
} = projectAPI;

export const {
  endpoints: {
    getAllProjects,
    getRootProjects,
    createProject,
    getChildrenById,
    getProjectDetails,
    moveProject,
    getAllManagedProjects,
    renameProject,
    updateProject
  }
} = projectAPI;
