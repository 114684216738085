import { IconButton, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useToggleState from '../../../hooks/useToggleState';
import Alert from '../../../components/Alert';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useWS } from 'contexts/WebSocketContext';

function ChatMessageActions({ message, onEdit, onDelete }) {
  const { enqueueSnackbar } = useSnackbar();
  const { deleteMessage } = useWS();
  const [deleteAlert, showDeleteAlert, hideDeleteAlert] = useToggleState(false);

  async function handleDelete() {
    try {
      hideDeleteAlert();
      // Call onDelete first to update UI immediately
      onDelete?.(message);
      
      deleteMessage({ 
        groupId: message.groupId || message.group, 
        messageId: message._id 
      });
      
      enqueueSnackbar('Delete message successfully', {
        variant: 'success'
      });
    } catch (e) {
      // On error, we could potentially add logic here to restore the message
      enqueueSnackbar(e.message ?? 'Something went wrong!', {
        variant: 'error'
      });
    }
  }

  return (
    <Stack direction="row">
      <IconButton size="small" color="buttonGray" onClick={onEdit}>
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" color="buttonGray" onClick={showDeleteAlert}>
        <DeleteIcon size="small" />
      </IconButton>
      {deleteAlert && (
        <Alert
          open
          title="Delete message"
          content="Are you sure to delete this message?"
          onDismiss={hideDeleteAlert}
          onConfirm={handleDelete}
        />
      )}
    </Stack>
  );
}

export default ChatMessageActions;
