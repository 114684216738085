import { useCallback, useRef, useState } from 'react';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import CancelIcon from '@mui/icons-material/Cancel';
import ChatEmojiDialog from './ChatEmojiDialog';
import useToggleState from 'hooks/useToggleState';
import { useGroupsContext } from 'contexts/GroupsContext';
import { useSnackbar } from 'hooks/useSnackbar';
import MentionInput from 'components/MentionInput';
import { insertEmojiToMentionText } from 'utils/mentionHelper';
import useTaggableUsers from 'hooks/useTaggableUsers';
import { useWS } from 'contexts/WebSocketContext';

function ChatMessageEditBox({ message, onClose, onUpdate }) {
  const { enqueueSnackbar } = useSnackbar();
  const [emojiAnchor, openEmojiDialog, closeEmojiDialog] = useToggleState(null);
  const [content, setContent] = useState(message.content);
  const inputRef = useRef();
  const { selectedGroup } = useGroupsContext();
  const { updateMessage } = useWS();
  const taggableUsers = useTaggableUsers({
    users: selectedGroup
      ? [...selectedGroup.members, selectedGroup.managedBy]
      : []
  });

  function handleOpenEmojiDialog(event) {
    openEmojiDialog?.(event.target);
  }

  function handleChange(e) {
    setContent(e.target.value);
  }

  async function handleSubmit() {
    if (!content.trim().length) return;
    try {
      const groupId = selectedGroup._id;
      const messageId = message._id;
      
      onUpdate?.(content.trim());
      
      updateMessage({
        groupId,
        messageId,
        content: content.trim()
      });
      
      onClose();
    } catch (e) {
      onClose();
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      void handleSubmit();
    }
  }

  const handleSelectEmoji = useCallback(
    (emoji) => {
      const caretPosition = inputRef.current?.selectionStart || 0;
      setContent((prevState) => {
        return insertEmojiToMentionText(prevState, emoji.native, caretPosition);
      });
      setTimeout(() => {
        inputRef.current.selectionStart = caretPosition + 2;
      }, 50);
    },
    [inputRef]
  );

  return (
    <>
      <Stack height={56} direction="row" alignItems="center" spacing={1}>
        <MentionInput
          placeholder="Write a message..."
          rounded
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleOpenEmojiDialog}>
                <InsertEmoticonIcon />
              </IconButton>
            </InputAdornment>
          }
          inputRef={inputRef}
          value={content}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          sx={{ backgroundColor: grey[200] }}
          // TODO WS: disabled
          // disabled={isUpdating}
          data={taggableUsers}
          singleLine
        />
        <Stack flexDirection="row">
          <IconButton
            // TODO WS: disabled
            // disabled={isUpdating}
            color="default"
            onClick={onClose}
            size="small"
          >
            <CancelIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={handleSubmit}
            // TODO WS: disabled
            // disabled={!content.trim() || isUpdating}
            size="small"
          >
            <CheckCircleIcon />
          </IconButton>
        </Stack>
      </Stack>
      <ChatEmojiDialog
        anchorEl={emojiAnchor}
        onClose={closeEmojiDialog}
        onSelect={handleSelectEmoji}
      />
    </>
  );
}

export default ChatMessageEditBox;
