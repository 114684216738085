import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useState } from 'react';

import { ReactNode } from 'react';

interface AccordionProps {
  children: ReactNode;
  disabled: boolean;
  defaultOpen: boolean;
  unmountOnExit: boolean;
  heading: ReactNode;
  padding?: boolean;
  borderBottom?: boolean;
  openFullHeight?: boolean;
  callback?: (open: boolean) => void;
}

const Accordion = ({
  children,
  disabled,
  defaultOpen,
  unmountOnExit,
  heading,
  padding = true,
  borderBottom = false,
  openFullHeight = false,
  callback = () => {}
}: AccordionProps) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <List
      sx={{
        ...(padding ? { py: 0.5 } : { py: 0 }),
        ...(borderBottom && { borderBottom: 1, borderColor: 'divider' }),
        ...(openFullHeight &&
          open && {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          })
      }}
    >
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          disabled={ disabled }
          component="button"
          onClick={() => {
            setOpen(!open);
            callback(!open);
          }}
          sx={{
            minHeight: 48,
            width: '100%',
            justifyContent: !disabled ? 'initial' : 'center',
            ...(padding && { px: 2.5 })
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr:  3 ,
              justifyContent: 'center'
            }}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </ListItemIcon>
          <ListItemText
            primary={heading}
            sx={{
              // opacity: disabled ? 0 : 1,
              '& .MuiTypography-root': { fontWeight: '700' }
            }}
          />
        </ListItemButton>
      </ListItem>
      <Collapse
        sx={{
          ...(openFullHeight &&
            open && {
              height: '100%!important',
              '& .MuiCollapse-wrapper': {
                height: '100%'
              },
              '& .MuiCollapse-wrapperInner': {
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }
            })
        }}
        in={open}
        timeout="auto"
        unmountOnExit={unmountOnExit}
      >
        {children}
      </Collapse>
    </List>
  );
};

export default Accordion;
