import { useAuth } from 'hooks/useAuth';
import { enqueueSnackbar } from 'notistack';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

export const webSocketType = {
  // actions
  CREATE_GROUP: 'create_group',
  UPDATE_GROUP: 'update_group',
  DELETE_GROUP: 'delete_group',
  CREATE_MESSAGE: 'create_message',
  UPDATE_MESSAGE: 'update_message',
  DELETE_MESSAGE: 'delete_message',
  //
  CHATGPT_MESSAGE: 'chatgpt_message',

  // events
  ROOM_CREATED: 'room_created',
  ROOM_UPDATED: 'room_updated',
  ROOM_DELETED: 'room_deleted',
  NOTIFICATION: 'notification'
};

/**
 * @type {React.Context<{
 *  messageHistory: {
 *    type: String,
 *    data: Object,
 *  }[],
 *  sendMessage: Function,
 *  connectionStatus: String,
 *  status: Number,
 *  lastEvent: {
 *   type: String,
 *   data: Object,
 *  },
 *  createGroup: ({ name: String, description: String, members: String[] }) => void,
 *  updateGroup: ({ id?: Number, name: String, description: String, members: String[] }) => void,
 *  deleteGroup: (id: String) => void,
 *  createMessage: ({ content: String, groupId: Number }) => void,
 *  updateMessage: ({ messageId: Number, content: String, groupId: Number }) => void,
 *  deleteMessage: ({ messageId: Number, groupId: Number }) => void,
 *  chatGPTMessage: ({ content: String }) => void
 *
 * }>}
 */
const WebSocketContext = createContext({});

const WebSocketContextProvider = ({ children }) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [lastEvent, setLastEvent] = useState(null);
  console.log(
    'file: WebSocketContext.js:55 ~ WebSocketContextProvider ~ lastEvent:',
    lastEvent
  );

  const { token } = useAuth();
  const [socketUrl, setSocketUrl] = useState(
    process.env.REACT_APP_WEBSOCKET_API + '?token=' + token
  );

  const [shouldReconnect, setShouldReconnect] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      console.log('Connection closed:', closeEvent);
      return shouldReconnect;
    },
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onOpen: () => {
      console.log('WebSocket connection established');
      setRetryCount(0);
    },
    onClose: () => {
      console.log('WebSocket connection closed');
      setRetryCount((prev) => prev + 1);
    },
    onError: (error) => {
      console.error('WebSocket error:', error);
    }
  });

  const createGroup = ({ name, description, members }) => {
    sendMessage(
      JSON.stringify({
        type: webSocketType.CREATE_GROUP,
        data: { name, description, members }
      })
    );
  };

  const updateGroup = ({ id, name, members }) => {
    sendMessage(
      JSON.stringify({
        type: webSocketType.UPDATE_GROUP,
        data: { id, name, members }
      })
    );
  };

  const deleteGroup = (id) => {
    sendMessage(
      JSON.stringify({
        type: webSocketType.DELETE_GROUP,
        data: { id }
      })
    );
  };

  const createMessage = ({ content, groupId }) => {
    sendMessage(
      JSON.stringify({
        type: webSocketType.CREATE_MESSAGE,
        data: { content, groupId }
      })
    );
  };

  const updateMessage = ( { messageId, content, groupId } ) => {
    if (!messageId || !content || !groupId) {
      console.error( 'messageId is required' );
      enqueueSnackbar( 'An error occurred when updating the message', { variant: 'error', autoHideDuration: 5000 } );
      return;
    }
    sendMessage(
      JSON.stringify({
        type: webSocketType.UPDATE_MESSAGE,
        data: { messageId, content, groupId }
      })
    );
    enqueueSnackbar( 'Message updated successfully', { variant: 'success', autoHideDuration: 3000 }, );
  };

  const deleteMessage = ({ messageId, groupId }) => {
    sendMessage(
      JSON.stringify({
        type: webSocketType.DELETE_MESSAGE,
        data: { messageId, groupId }
      })
    );
  };

  const chatGPTMessage = ({ content, slideId, groupId }) => {
    sendMessage(
      JSON.stringify({
        type: webSocketType.CHATGPT_MESSAGE,
        data: { content, slideId, groupId }
      })
    );
  };

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage.data) {
        setLastEvent(JSON.parse(lastMessage.data));
      }
      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    setSocketUrl(process.env.REACT_APP_WEBSOCKET_API + '?token=' + token);
  }, [token]);

  const handleSendMessage = useCallback(
    (msg) => sendMessage(msg),
    [sendMessage]
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState];

  useEffect(() => {
    console.log('Connection is: ', connectionStatus);
  }, [connectionStatus]);

  return (
    <WebSocketContext.Provider
      value={{
        messageHistory,
        sendMessage: handleSendMessage,
        connectionStatus,
        status: readyState,
        lastEvent,
        createGroup,
        updateGroup,
        deleteGroup,
        createMessage,
        updateMessage,
        deleteMessage,
        chatGPTMessage
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

WebSocketContext.displayName = 'WebSocketContext';

const useWS = () => useContext(WebSocketContext);

export { useWS, WebSocketContextProvider };
