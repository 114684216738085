import { useAuth } from 'hooks/useAuth';
import { createContext, useContext, useMemo } from 'react';
import _ from 'lodash';

export const defaultSlideBoxContextState = {
  selectedNodeId: '',
  selectedNodeType: '',
  openDetail: false,
  openMoveFolder: false,
  selectedRows: [],
  viewComponent: 'file_explorer',
  dialogData: {
    type: '', // project, slide
    data: null
  },
  projectSlides: null,
};

export const slideBoxContextReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE': {
      return { ...state, [action.key]: action.value };
    }
    case 'TOGGLE_SELECTED_ROW': {
      const cloned = _.cloneDeep(state.selectedRows);
      const found = cloned.findIndex((r) => r._id === action.value._id);
      if (found !== -1) {
        cloned.splice(found, 1);
      } else {
        cloned.push(action.value);
      }
      return { ...state, selectedRows: cloned };
    }
    default:
      return state;
  }
};

export const SlideboxContext = createContext();

export const useSlideboxContext = () => {
  const [state, dispatch] = useContext(SlideboxContext);

  const {
    user: { _id: userId }
  } = useAuth();

  const setDialogData = (type, data) => {
    dispatch({ type: 'SET_STATE', key: 'dialogData', value: { type, data } });
  };

  const setProjectSlides = (data) => {
    dispatch({ type: 'SET_STATE', key: 'projectSlides', value: data });
  };

  const setViewComponent = (component) => {
    dispatch({ type: 'SET_STATE', key: 'viewComponent', value: component });
  };

  return {
    state,
    dispatch,
    isSelected: useMemo(() => {
      return !!state.selectedRows.length;
    }, [state.selectedRows]),
    isSingleSelected: useMemo(() => {
      return state.selectedRows.length === 1;
    }, [state.selectedRows]),
    firstSelectedElement: useMemo(() => {
      return (state.selectedRows.length && state.selectedRows[0]) || null;
    }, [state.selectedRows]),
    isOwner: useMemo(() => {
      return !state.selectedRows.some((row) => row.createdBy._id !== userId);
    }, [state.selectedRows, userId]),
    dialogData: useMemo(() => {
      return state.dialogData;
    }, [state.dialogData]),
    projectSlides: useMemo(() => {
      return state.projectSlides;
    }, [state.projectSlides]),
    viewComponent: useMemo(() => {
      return state.viewComponent;
    }, [state.viewComponent]),
    setDialogData,
    setProjectSlides,
    setViewComponent
  };
};
