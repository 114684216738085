import { api } from './api';

type UploadResponse = {
  success: boolean;
  data: {
    url: string;
    path: string;
  }
}

export const uploadApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadImage: build.mutation<{ url: string }, FormData>({
      query: (formData) => ({
        url: '/upload/image/',
        method: 'POST',
        body: formData,
        formData: true,
      }),
      transformResponse: (response: UploadResponse) => ({
        url: response.data.url
      }),
    }),
  }),
});

export const { useUploadImageMutation } = uploadApi;