import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { Layers } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import InProcessingDialog from '../../InProcessingDialog';
import { useGetSlideProcessingStatusMutation } from 'services/slides';
import { useSnackbar } from 'notistack';
import { FILE_TYPES } from 'constants';

const NavigationPaneFile = ( { file, paddingLeft, ...props } ) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { dispatch } = useSlideboxContext();
  const [ getSlideProcessingStatus ] = useGetSlideProcessingStatusMutation();
  const { enqueueSnackbar } = useSnackbar();
  const isMCDFile = file.type === FILE_TYPES.MCD;

  const [ openInProcessingDialog, setOpenInProcessingDialog ] =
    React.useState( false );

  const handleFileClick = async () => {
    if (isMCDFile) {
      dispatch({ type: 'SET_STATE', key: 'selectedRows', value: [] });
      navigate(`/slideViewer/mcd/${file._id}`, { replace: true });
      return;
    }

    try {
      const { success, data: slideProcessingStatus } =
        await getSlideProcessingStatus( file._id ).unwrap();
      if ( success ) {
        if ( ( !file.gcsFileName && !file.tilePath ) || slideProcessingStatus ) {
          setOpenInProcessingDialog( true );
        } else {
          dispatch( { type: 'SET_STATE', key: 'selectedRows', value: [] } );
          navigate( `/slideViewer/${ file._id }`, { replace: true } );
        }
      }
    } catch ( err ) {
      const errorMessage =
        err?.data?.error ||
        err?.data?.error?.message ||
        'Something went wrong !';
      enqueueSnackbar( errorMessage, {
        variant: 'error'
      } );
      setTimeout( () => navigate( `/slidebox` ), 3000 );
    }
  };
  return (
    <>
      <ListItem
        onClick={handleFileClick}
        sx={{
          color: theme.palette.primary.main,
          borderRadius: ' 0px 50px 50px 0px',
          ' .MuiListItemSecondaryAction-root': {
            left: `${ paddingLeft }px`,
            right: 'unset'
          },
          ' .MuiListItemIcon-root': {
            color: theme.palette.primary.main
          }
        }}
        disablePadding
      >
        <ListItemButton
          role={undefined}
          dense
          sx={{
            paddingLeft: `${ 30 + paddingLeft }px`,
            paddingRight: '8px!important',
            borderRadius: ' 0px 50px 50px 0px'
          }}
        >
          <ListItemIcon sx={{ minWidth: 'unset' }}>
            {isMCDFile ? <Layers /> : <InsertPhotoIcon />}
          </ListItemIcon>
          <ListItemText
            primary={file.name}
            sx={{
              my: 0,
              textTransform: 'none',
              '& span': { fontSize: '16px' },
              whiteSpace: 'nowrap'
            }}
          />
        </ListItemButton>
      </ListItem>
      <InProcessingDialog
        open={openInProcessingDialog}
        handleClose={() => setOpenInProcessingDialog( false )}
      />
    </>
  );
};

export default NavigationPaneFile;
