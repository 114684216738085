import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Input,
  InputLabel,
  Stack,
  Typography
} from '@mui/material';
import { useGroupsContext } from 'contexts/GroupsContext';
import { useWS } from 'contexts/WebSocketContext';
import map from 'lodash/map';
import {
  AutocompleteElement,
  TextFieldElement,
  useForm
} from 'react-hook-form-mui';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useGetMembersQuery } from '../../../services/user';
import { useState } from 'react';

function EditGroupDialog( { group, open, onClose, editable } ) {
  const { enqueueSnackbar } = useSnackbar();
  const { setGroups } = useGroupsContext();
  const { isLoading, data } = useGetMembersQuery();
  const { updateGroup, deleteGroup } = useWS();
  const { control, handleSubmit } = useForm( {
    defaultValues: {
      name: group.name,
      // description: group.description, //* The description field is not defined on the BE entity
      members: map( group.members, '_id' )
    }
  } );
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  if ( !group || !data ) return null;

  const members = data
    .map( ( item ) => ( {
      id: item._id,
      label: `${ item.firstName } ${ item.lastName }`.trim()
    } ) )
    .sort( ( a, b ) => a.label.localeCompare( b.label ) )
    ?? [];

  async function handleUpdateGroup( formData ) {
    if ( !editable ) return;
    try {
      updateGroup( {
        id: group._id,
        ...formData
      } );
      // Update the group state in context after successful update
      setGroups( ( prevGroups ) => {
        const index = prevGroups.findIndex( ( g ) => g._id === group._id );
        if ( index === -1 ) return prevGroups;

        const updatedGroups = [ ...prevGroups ];
        updatedGroups[ index ] = {
          ...updatedGroups[ index ],
          ...formData,
          members: data.filter( ( item ) => formData.members.includes( item._id ) )
        };

        return updatedGroups;
      } );
      onClose?.();
      enqueueSnackbar( 'Update group successfully', { variant: 'success' } );
    } catch ( e ) {
      enqueueSnackbar( e.message || 'Something went wrong!', {
        variant: 'error'
      } );
    }
  }

  const handleDeleteGroup = async () => {
    try {
      deleteGroup(group._id);
      setGroups(prevGroups => prevGroups.filter(g => g._id !== group._id));
      onClose?.();
      enqueueSnackbar('Group deleted successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message || 'Something went wrong!', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <form
            noValidate
            id="group-edit-form"
            onSubmit={handleSubmit(handleUpdateGroup)}
          >
            <Stack spacing={3}>
              <Typography variant="h5" align="center">Edit Group</Typography>
              <Stack spacing={1}>
                <InputLabel required htmlFor="group-name">
                  Group name
                </InputLabel>
                <TextFieldElement
                  control={control}
                  name="name"
                  fullWidth
                  id="group-name"
                  required
                  disabled={!editable}
                />
              </Stack>
              <Stack spacing={1}>
                <InputLabel required htmlFor="group-members">
                  Members
                </InputLabel>
                <AutocompleteElement
                  control={control}
                  loading={isLoading}
                  multiple
                  matchId
                  id="group-members"
                  name="members"
                  options={members}
                  required
                  autocompleteProps={{
                    disabled: !editable
                  }}
                />
              </Stack>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant="contained"
            color="gray"
            disableElevation
          >
            Back
          </Button>
          {editable && (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={() => setDeleteConfirmOpen(true)}
              >
                Delete
              </Button>
              <Button
                type="submit"
                form="group-edit-form"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Delete Group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this group? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteGroup}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditGroupDialog;
