import { createContext, useContext, useEffect, useState } from 'react';
import { useWS, webSocketType } from './WebSocketContext';
import { useAuth } from 'hooks/useAuth';
import { isAccessGroup } from 'utils/websocket';

const GroupsContext = createContext({});

const GroupsContextProvider = ({ groups: initGroups, groupId, children }) => {
  const [groups, setGroups] = useState(initGroups || []);
  const [filteredGroups, setFilteredGroups] = useState(initGroups || []);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [lastMessage, setLastMessage] = useState(null);

  const { lastEvent } = useWS();
  const { user } = useAuth();

  // update new list group when initGroups is updated
  useEffect(() => {
    if (initGroups) {
      setGroups(initGroups);
    }
  }, [initGroups]);

  // update selected group when groupId is updated
  useEffect(() => {
    if (groupId) {
      setSelectedGroup(
        groups.find((item) => item._id === parseInt(groupId)) || null
      );
    }
  }, [groupId, groups]);

  // update selected group when selected group is updated
  useEffect(() => {
    if (selectedGroup) {
      setSelectedGroup(
        groups.find((item) => item._id === selectedGroup._id) || null
      );
    }
  }, [groups, selectedGroup]);

  // update groups when socket event is triggered
  useEffect(() => {
    if (lastEvent) {
      if (!isAccessGroup(lastEvent, user._id)) return;
      switch (lastEvent.type) {
        case webSocketType.ROOM_CREATED:
          setGroups((prevState) => [lastEvent.data, ...prevState]);
          break;
        case webSocketType.ROOM_UPDATED:
          setGroups((prevState) =>
            prevState.map((item) =>
              item._id === lastEvent.data._id ? lastEvent.data : item
            )
          );
          break;
        case webSocketType.ROOM_DELETED:
          setGroups((prevState) =>
            prevState.filter((item) => item._id !== lastEvent.data._id)
          );
          break;
        default:
      }
    }
  }, [lastEvent, user._id]);

  useEffect(() => {
    if (lastEvent) {
      if (
        lastEvent.type === webSocketType.CREATE_MESSAGE &&
        lastEvent.data.groupId === selectedGroup?._id
      ) {
        // Format the message data before setting it
        const formattedMessage = {
          ...lastEvent.data,
          group: lastEvent.data.groupId,
          createdAt: new Date().toISOString()
        };
        setLastMessage({
          ...lastEvent,
          data: formattedMessage
        });
      }
    }
  }, [lastEvent, selectedGroup?._id, user?._id]);

  const setFilter = (keyword) => {
    if (!keyword) {
      setFilteredGroups(groups);
      return;
    }
    const filtered = groups.filter(group => 
      group.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredGroups(filtered);
  };

  // Update filtered groups when groups change
  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  return (
    <GroupsContext.Provider
      value={{
        groups,
        filteredGroups,
        setFilter,
        selectedGroup,
        setSelectedGroup,
        setGroups,
        lastMessage,
        setLastMessage
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
};

GroupsContext.displayName = 'GroupsContext';

const useGroupsContext = () => useContext(GroupsContext);

export { useGroupsContext, GroupsContextProvider };
