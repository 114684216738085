import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  EOrganizationStatus,
  IOrganization,
  IOrganizationTableFilterValue,
  IOrganizationTableFilters
} from 'types/organization';
import Scrollbar from 'v2/components/scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  emptyRows,
  getComparator,
  useTable
} from 'v2/components/table';
import OrganizationTableFiltersResult from './OrganizationTableFiltersResult';
import OrganizationTableRow from './OrganizationTableRow';
import OrganizationTableToolbar from './OrganizationTableToolbar';

interface Props {
  organizations: IOrganization[];
  onRefresh: VoidFunction;
  onCreateSuccess: VoidFunction;
}

const defaultFilters: IOrganizationTableFilters = {
  name: '',
  status: undefined
};

const TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'description', label: 'Description' },
  { id: 'email', label: 'Email' },
  { id: 'address', label: 'Address' },
  { id: 'phone', label: 'Phone' },
  { id: 'collaboratorLimit', label: 'Collaborator Limit', align: 'center' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: '', width: 88 }
];

const Organizations = ({ organizations, onRefresh }: Props) => {
  const table = useTable();

  const [tableData, setTableData] = useState(organizations);

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters
  });

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IOrganizationTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  useEffect(() => {
    setTableData(organizations);
  }, [organizations]);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <OrganizationTableToolbar
        filters={filters}
        onFilters={handleFilters}
        onCreateSuccess={onRefresh}
      />

      {canReset && (
        <OrganizationTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          onResetFilters={handleResetFilters}
          results={dataFiltered.length}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
            />

            <TableBody>
              {dataFiltered
                .slice(
                  table.page * table.rowsPerPage,
                  table.page * table.rowsPerPage + table.rowsPerPage
                )
                .map((row) => (
                  <OrganizationTableRow
                    key={row._id}
                    row={row}
                    onRefresh={onRefresh}
                  />
                ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(
                  table.page,
                  table.rowsPerPage,
                  tableData.length
                )}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={dataFiltered.length}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Box>
  );
};

export function applyFilter({
  inputData,
  comparator,
  filters
}: {
  inputData: IOrganization[];
  comparator: (a: any, b: any) => number;
  filters: IOrganizationTableFilters;
}) {
  const { name, status } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    const searchTerm = name.toLowerCase().trim();
    inputData = inputData.filter((org) => {
      const searchableFields = [
        org.name,
        org.email,
        org.description,
        org.address,
        org.phone
      ];
      
      return searchableFields.some((field) => {
        if (!field) return false;
        const fieldValue = field.toLowerCase().trim();
        //* Match if field contains the exact search term as a whole word or if it contains the search term as part of a word
        return fieldValue.includes(searchTerm) || 
               fieldValue.split(/\s+/).some(word => word.includes(searchTerm));
      });
    });
  }

  if (status) {
    inputData = inputData.filter((item) =>
      status === EOrganizationStatus.ACTIVE ? item.isActive : !item.isActive
    );
  }

  return inputData;
}

export default Organizations;
