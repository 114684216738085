import { IOrganization } from './organization';

export type IUserTableFilterValue = string | string[] | number[] | Date | null;

export type IUserTableFilters = {
  name: string;
  role?: string[];
  status?: EUserStatus;
  organizations?: number[];
  start_date?: Date | null;
  end_date?: Date | null;
};

export type IUser = {
  _id: number;
  firstName: string;
  lastName: string;
  email: string;
  is_active: boolean;
  role: EUserRole;
  isActive: boolean;
  organization: IOrganization;
  type: EUserType;
  expiredDate?: string;
  createdBy: IUser;
  createdAt: string;
  avatarURL?: string;
};

export enum EUserRole {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  COLLABORATOR = 'collaborator',
  USER = 'user'
}

export enum EUserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum EUserType {
  INVITE = 'invite',
  RESET_PASSWORD = 'reset-password',
  CHANGE_PASSWORD = 'change-password',
  ACTIVATE = 'activate'
}
