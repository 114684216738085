import React, { useRef, useState } from 'react';
import { Box, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchBox({ expanded, onExpand, value, onSubmit }) {
  const [keyword, setKeyword] = useState(value);
  const inputRef = useRef(null);

  function handleChange(event) {
    const newValue = event.target.value;
    setKeyword(newValue);
    onSubmit(newValue);
  }

  function handleExpand() {
    onExpand?.();
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 200);
  }

  function handleSubmitSearch() {
    onSubmit?.(keyword);
  }

  function handleKeyDown(e) {
    if (e.code === 'Enter') {
      onSubmit?.(keyword);
    }
  }

  const searchIconButton = (
    <IconButton onClick={handleExpand}>
      <SearchIcon />
    </IconButton>
  );

  return (
    <Box height={40}>
      {expanded ? (
        <OutlinedInput
          inputRef={inputRef}
          type="text"
          value={keyword}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          size="small"
          placeholder="Search..."
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleSubmitSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      ) : (
        searchIconButton
      )}
    </Box>
  );
}

export default SearchBox;
