import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const SaveButton = ({ onClick, disabled }) => {
  return (
    <Button
      variant="outlined"
      startIcon={<SaveIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      Save Image
    </Button>
  );
};

export default SaveButton;