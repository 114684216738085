import {
  Avatar,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
// import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { grey } from '@mui/material/colors';
import useToggleState from '../../../hooks/useToggleState';
import EditGroupDialog from './EditGroupDialog';
// import InviteMemberDialog from './InviteMembersDialog';
import { ROLES } from '../../../constants';
import { useAuth } from '../../../hooks/useAuth';
import { stringToColor } from '../../../utils/avatar';

// Remove stringToColor function

function GroupDetailHeader({ group }) {
  const [editGroup, openEditDialog, closeEditDialog] = useToggleState(null);
  // const [openInviteDialog] = useToggleState(null);
  const {
    user: { _id, role }
  } = useAuth();

  function handleOpenEditDialog() {
    openEditDialog?.(group);
  }

  // function handleOpenInviteDialog() {
  //   openInviteDialog?.(group);
  // }

  if (!group) return null;

  const canEdit = role === ROLES.SUPER_ADMIN || group.managedBy._id === _id;

  return (
    <Card
      variant="outlined"
      sx={{
        borderLeft: 'none',
        borderRight: 'none',
        backgroundColor: grey[200]
      }}
    >
      <CardContent>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            sx={{ 
              bgcolor: stringToColor(group.name),
              width: 40,
              height: 40
            }}
          >
            {group.name.charAt(0).toUpperCase()}
          </Avatar>
          <Stack flex={1} justifyContent="center">
            <Typography variant="h6" pb={0}>
              {group.name}
            </Typography>
            {group.description && (
              <Typography variant="body2" color={grey[700]} sx={{ mb: 0.5 }}>
                {group.description}
              </Typography>
            )}
            <Typography variant="caption" color={grey[600]} lineHeight={1}>
              {group.members?.length ?? 0} members
            </Typography>
            <Typography variant="caption" color={grey[800]}>
              {group.online?.length ?? 0} online
            </Typography>
          </Stack>
          <Stack direction="row">
            {/* {canEdit && (
              <IconButton onClick={handleOpenInviteDialog}>
                <PersonAddAlt1Icon />
              </IconButton>
            )} */}
            {canEdit && <IconButton onClick={handleOpenEditDialog}>
              <MoreVertIcon />
            </IconButton>}
          </Stack>
        </Stack>
      </CardContent>
      {editGroup && (
        <EditGroupDialog
          editable={canEdit}
          group={editGroup}
          open
          onClose={closeEditDialog}
        />
      )}
      {/* //* Not necessary because this function is included on EditGroupDialog  */}
      {/* <InviteMemberDialog
        group={inviteMemberFlag}
        open={!!inviteMemberFlag}
        onClose={closeEditDialog}
      /> */}
    </Card>
  );
}

export default GroupDetailHeader;
