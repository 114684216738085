import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useGroupsContext } from 'contexts/GroupsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { stringToColor } from '../../../utils/avatar';

function GroupList({ expanded }) {
  const { filteredGroups: groups, selectedGroup, setSelectedGroup } = useGroupsContext();

  const navigate = useNavigate();
  const { id } = useParams();

  const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name));

  const handleClick = (item) => () => {
    if (item._id === id) return;
    setSelectedGroup(item);
    navigate(`/groups/${item._id}`);
  };

  return (
    <List>
      {sortedGroups.map((item) => (
        <ListItem
          selected={item._id === selectedGroup?._id}
          key={item._id}
          disablePadding
        >
          <ListItemButton
            sx={expanded ? null : { paddingLeft: 0 }}
            onClick={handleClick(item)}
          >
            <ListItemAvatar>
              <Avatar 
                sx={{ 
                  bgcolor: stringToColor(item.name),
                  width: 40,
                  height: 40
                }}
              >
                {item.name.charAt(0).toUpperCase()}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              hidden={!expanded}
              primary={item.name}
              // TODO WS handle last message
              // secondary={getMentionText(
              //   lastMessages[item.id] ?? item.lastMessage
              // )}
              secondaryTypographyProps={{
                maxWidth: 'calc(100% - 20px)',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            />
            <Typography variant="caption" hidden={!expanded}>
              {item.members.length} members
            </Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

export default GroupList;
