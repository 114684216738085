
import { useCallback, useRef } from 'react';

const DEFAULT_DELAY = 300;

/**
 * Custom hook that returns a debounced version of the provided callback function.
 *
 * @param {Function} callback - The function to debounce.
 * @param {number} [delay=DEFAULT_DELAY] - The delay in milliseconds.
 * @returns {Function} - The debounced function.
 */
export function useDebounce( callback, delay = DEFAULT_DELAY ) {
  const timeoutId = useRef(null);

  return useCallback((...args) => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
}