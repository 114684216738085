import { api } from './api';

export const slideAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getFileById: build.mutation({
      query: (id) => ({
        url: `/slides/${id}`,
        method: 'GET'
      })
    }),
    updateSlide: build.mutation({
      query: ({ id, ...rest }) => ({
        url: `/slides/${id}`,
        method: 'PATCH',
        body: { ...rest }
      })
    }),
    uploadSlideAttachment: build.mutation({
      query: ({ id, body }) => ({
        url: `/slides/${id}/attachments`,
        method: 'POST',
        body
      })
    }),
    getDownloadUrl: build.mutation({
      query: (id) => ({
        url: `/slides/getFileSignedUrl/${id}`,
        method: 'GET'
      })
    }),
    // generateDeepZoomFiles: build.mutation({
    //   query: (id) => ({
    //     url: `/slides/generateDeepZoomFiles/${id}`,
    //     method: 'POST'
    //   })
    // }),
    deleteSlide: build.mutation({
      query: (id) => ({
        url: `/slides/${id}`,
        method: 'DELETE'
      })
    }),
    exportAnnotations: build.mutation({
      query: ({ projectName, slideName, userEmail }) => ({
      url: '/slides/exportAnnotations',
      method: 'POST',
      body: {
        projectName,
        slideName,
        userEmail
      }
      })
    }),
    getSlideActivityLogs: build.mutation({
      query: (id) => ({
        url: `/slides/${id}/activityLogs`,
        method: 'GET'
      })
    }),
    getAllSlideAnnotations: build.query({
      query: (id) => ({
        url: `/slides/${id}/annotations`,
        method: 'GET'
      }),
      transformResponse: (response) => {
        const mapped = {};
        if (response) {
          const { data, success } = response;
          if (success) {
            const areaIndexMap = {};
            mapped.data = data
              .map((anno) => {
                areaIndexMap[anno.stroke] = areaIndexMap[anno.stroke] ?? 0;
                return {
                  ...anno,
                  title: anno.title || `Area ${++areaIndexMap[anno.stroke]}`,
                  id: anno._id,
                  body: anno.body || []
                };
              })
              .sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA;
              });
          }
        }
        return { ...response, ...mapped };
      },
      providesTags: [{ type: 'Annotations', id: 'LIST' }]
    }),
    getSlideSharedUsers: build.query({
      query: (id) => ({
        url: `/slides/${id}/shared-users`,
        method: 'GET'
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) => [{ type: 'SlideUsers', id: arg }]
    }),
    shareSlideWithUsers: build.mutation({
      query: ({ id, users }) => ({
        url: `/slides/${id}/shared-users`,
        method: 'PUT',
        body: { users }
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'SlideUsers', id: arg.id }
      ]
    }),
    getSlideComments: build.query({
      query: (id) => ({
        url: `/slides/${id}/comments`,
        method: 'GET'
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) => [{ type: 'SlideComments', id: arg }]
    }),
    createSlideComment: build.mutation({
      query: ({ id, content }) => ({
        url: `/slides/${id}/comments`,
        body: { content },
        method: 'POST'
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (result, error, arg) => [
        { type: 'SlideComments', id: arg.id }
      ]
    }),
    updateSlideComment: build.mutation({
      query: ({ slideId, commentId, content }) => ({
        url: `/slides/${slideId}/comments/${commentId}`,
        body: { content },
        method: 'PATCH'
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (result, error, arg) => [
        { type: 'SlideComments', id: arg.slideId }
      ]
    }),
    deleteSlideComment: build.mutation({
      query: ({ slideId, commentId }) => ({
        url: `/slides/${slideId}/comments/${commentId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'SlideComments', id: arg.slideId }
      ]
    }),
    updateSlideGroup: build.mutation({
      query: ({ id, group }) => ({
        url: `/slides/${id}/groups`,
        method: 'PATCH',
        body: group
      })
    }),
    deleteSlideGroup: build.mutation({
      query: ({ id, groupId }) => ({
        url: `/slides/${id}/groups`,
        method: 'DELETE',
        body: {
          Id: groupId
        }
      }),
      invalidatesTags: (result, error, arg) => {
        if (!error) return [{ type: 'Annotations', id: 'LIST' }];
      }
    }),
    getSlideProcessingStatus: build.mutation({
      query: (id) => ({
        url: `/slides/processing-status/${id}`,
        method: 'GET'
      })
    }),
    getSlideNames: build.query({
      query: (params) => ({ url: '/slides/names', params }),
      transformResponse: (response) => response.data
    }),
    getSlideGroups: build.query({
      query: (params) => ({ url: '/slides/groups', params }),
      transformResponse: (response) => response.data
    }),
    copySlideToProject: build.mutation({
      query: (body) => ({
        url: `/slides/copy/`,
        body: body,
        method: 'POST'
      }),
      transformResponse: (response) => response.data
    }),
    moveSlideToProject: build.mutation({
      query: (body) => ({
        url: `/slides/move/`,
        body: body,
        method: 'POST'
      }),
      transformResponse: (response) => response.data
    }),
    uploadSlideToProject: build.mutation({
      query: ({ file, slideName, createdByEmail, tags=[], caseIdentifiers=[], projectName='project', folderName='' }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('slideName', slideName);
        formData.append('createdByEmail', createdByEmail);
        formData.append('tags', JSON.stringify(tags));
        formData.append('caseIdentifiers', JSON.stringify(caseIdentifiers));
        formData.append('projectName', projectName);

        return {
          url: '/slides/uploadSlideToProject/',
          method: 'POST',
          body: formData,
        };
      }
    }),
  })
});

export const {
  // useGenerateDeepZoomFilesMutation,
  useCopySlideToProjectMutation,
  useCreateSlideCommentMutation,
  useDeleteSlideCommentMutation,
  useDeleteSlideGroupMutation,
  useDeleteSlideMutation,
  useExportAnnotationsMutation,
  useGetAllSlideAnnotationsQuery,
  useGetDownloadUrlMutation,
  useGetFileByIdMutation,
  useGetSlideActivityLogsMutation,
  useGetSlideCommentsQuery,
  useGetSlideGroupsQuery,
  useGetSlideNamesQuery,
  useGetSlideProcessingStatusMutation,
  useGetSlideSharedUsersQuery,
  useLazyGetAllSlideAnnotationsQuery,
  useMoveSlideToProjectMutation,
  useRenameSlideMutation,
  useShareSlideWithUsersMutation,
  useUpdateSlideCommentMutation,
  useUpdateSlideGroupMutation,
  useUpdateSlideMutation,
  useUploadSlideAttachmentMutation,
  useUploadSlideToProjectMutation
} = slideAPI;

export const {
  endpoints: {
    getChildrenById,
    deleteSlide,
    exportAnnotations,
    getSlideProcessingStatus
  }
} = slideAPI;
