import { MCDAreaData } from 'features/SlideViewer/ImageViewer/MCDAreaDataPopUp';
import _ from 'lodash';

export const MCDAreaDataMock: MCDAreaData[] = _.range(4, 101).map(i => ({
    channel: `Channel ${i}`,
    CV: _.random(1, 50),
    integral: _.random(100, 1000),
    label: `Label ${i}`,
    max: _.random(1000, 5000),
    mean: _.random(500, 2500),
    min: _.random(0, 500),
}));