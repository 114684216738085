import { Box, Button, MenuItem, Select } from '@mui/material';
import Accordion from 'components/Accordion';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { useContext, useState } from 'react';
import { FormContainer } from 'react-hook-form-mui';


export default function MCDSlidesSelector() {
    const [ state, dispatch ] = useContext(SlideViewerContext);
    const slideList = state.MCDSlides.map((slide: { name: string, selected: boolean; }) => slide.name);
    const currentSlide = state.MCDSlides.find((slide: { selected: boolean; }) => slide.selected)?.name || '';

    const [ selectedSlide, setSelectedSlide ] = useState(currentSlide);

    const handleSlideSubmission = (formData: { slide: string; }) => {
        dispatch({ type: 'SELECT_MCD_SLIDE', payload: selectedSlide });
    };

    return (
        <Accordion
            unmountOnExit={ false }
            heading="Slides"
            defaultOpen={ false }
            disabled={ false }>
            <Box mt={ 1 } mb={ 1 } p={ 2 }>
                { slideList.length === 0 ? (
                    <Box sx={ { textAlign: 'center', color: 'text.secondary' } }>
                        No slides available
                    </Box>
                ) : (
                    <FormContainer onSuccess={ handleSlideSubmission } values={ { slide: currentSlide } }>
                        <Select
                            name="slide"
                            defaultValue={ currentSlide }
                            fullWidth
                            size="small"
                            sx={ {
                                '& .MuiSelect-select': {
                                    paddingY: 1,
                                },
                            } }
                            onChange={ (e) => setSelectedSlide(e.target.value) }
                        >
                            { slideList.map((option: string) => (
                                <MenuItem key={ option } value={ option }
                                    sx={ {
                                        paddingY: 1,
                                        fontSize: '0.875rem',
                                    } }>
                                    { option }
                                </MenuItem>
                            )) }
                        </Select>
                        {/* <Box mt={ 2 }>
                            <Button type="submit" variant="contained" color="primary">
                                Update
                            </Button>
                        </Box> */}
                    </FormContainer>
                ) }
            </Box>
        </Accordion>
    );
}