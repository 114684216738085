import { Box, Button, MenuItem, Select } from '@mui/material';
import Accordion from 'components/Accordion';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { useContext, useEffect, useState } from 'react';

import { FormContainer } from 'react-hook-form-mui';
import { MCDSlideAcquisition } from 'types/MCDTypes';

export default function MCDROISelector() {
    const [ state, dispatch ] = useContext(SlideViewerContext);
    const roiNames = state.MCDROIs.map((roi: MCDSlideAcquisition) => roi.description);
    const currentROI = state.MCDROIs.find((roi: MCDSlideAcquisition) => roi.selected)?.description || '';

    const [ selectedROI, setSelectedROI ] = useState(currentROI);

    useEffect(() => {
        setSelectedROI(currentROI);
    }, [ currentROI ]);

    const selectROI = (data: any) => {
        dispatch({ type: 'SELECT_MCD_ROI', payload: selectedROI });
    };

    useEffect(() => {
        dispatch({ type: 'SELECT_MCD_ROI', payload: selectedROI });
    }, [ selectedROI ]);

    return (
        <Accordion
            unmountOnExit={ true }
            heading="ROIs"
            defaultOpen={ false }
            disabled={ false }
        >
            <Box mt={ 1 } mb={ 1 } p={ 2 }>
                <FormContainer onSuccess={ selectROI } values={ { roi: selectedROI } }>
                    <Select
                        name="panorama"
                        value={ selectedROI }
                        fullWidth
                        size="small"
                        sx={ {
                            '& .MuiSelect-select': {
                                paddingY: 1,
                            },
                        } }
                        onChange={ (e) => setSelectedROI(e.target.value) }
                    >
                        { roiNames.map((option: string) => (
                            <MenuItem
                                key={ option }
                                value={ option }
                                sx={ {
                                    paddingY: 1, // Adjust vertical padding
                                    fontSize: '0.875rem', // Optional: reduce font size for more density
                                } }
                            >
                                { option }
                            </MenuItem>
                        )) }
                    </Select>
                    {/* <Box mt={ 2 }>
                        <Button type="submit" variant="contained" color="primary">
                            Update
                        </Button>
                    </Box> */}
                </FormContainer>
            </Box>
        </Accordion>
    );
}