import { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  ListItemText,
  Collapse,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItem,
  useTheme,
  CircularProgress
} from '@mui/material';
import {
  ArrowRight as ArrowRightIcon,
  ArrowDropDown as ArrowDropDownIcon,
  CreateNewFolder,
  Folder,
  FolderOpen
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import TreeFileItem from './NavigationPaneFile';
import { FILE_TYPES } from '../../../../constants';
import {
  pushFoldersAndFiles,
  selectCurrentFolderView,
  selectExpandFloder,
  selectFoldersAndFiles,
  setExpandFolder
} from '../../../../stores/slidebox/slideboxSlice';
import { useGetProjectFolderChildrenByIdMutation } from '../../../../services/projects';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { useSlideBox } from 'hooks/slideBox/useSlideBox';

const NavigationPaneFolder = ({
  folder,
  paddingLeft = 0,
  setOpen: setOpenParent,
  ...props
}) => {
  const { dispatch } = useSlideboxContext();
  const reduxDispatch = useDispatch();
  const theme = useTheme();
  const foldersAndFiles = useSelector(selectFoldersAndFiles);
  const currentFolderView = useSelector(selectCurrentFolderView);
  const isFetched = useRef(false);
  const expandFloder = useSelector(selectExpandFloder);
  const [open, setOpen] = useState(
    expandFloder.indexOf(folder._id) !== -1 ? true : false
  );
  const [getProjectFolderChildrenById, { isLoading }] =
    useGetProjectFolderChildrenByIdMutation();

  const { handleSetCurrentView } = useSlideBox();

  const childrenTreeNodes = useMemo(() => {
    return foldersAndFiles.filter((item) => item.parent === folder._id);
  }, [foldersAndFiles, folder]);

  const fetchFoldersAndFiles = () => {
    getProjectFolderChildrenById(folder._id)
      .unwrap()
      .then((response) => {
        reduxDispatch( pushFoldersAndFiles( response ) );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFolderItemClick = () => {
    handleSetCurrentView(folder._id);
    setOpenParent(true);
    handleExpand({ stopPropagation: () => {} });
    dispatch({ type: 'SET_STATE', key: 'selectedRows', value: [] });
  };

  const handleExpand = (event) => {
    event.stopPropagation();
    if (!open && !isFetched.current) {
      reduxDispatch( setExpandFolder( [ ...expandFloder, folder._id ] ) );
      isFetched.current = true;
      fetchFoldersAndFiles();
    } else {
      reduxDispatch(
        setExpandFolder( expandFloder.filter( ( v ) => v !== folder._id ) )
      );
    }
    setOpen(!open);
  };

  const renderFolderIcon = (folder) => {
    if (folder.type === FILE_TYPES.FOLDER) {
      return <Folder sx={{ marginRight: '10px', fontSize: '20px' }} />;
    }

    return <CreateNewFolder sx={{ marginRight: '10px', fontSize: '20px' }} />;
  };

  useEffect(() => {
    if (currentFolderView._id === folder._id) {
      setOpen(true);
    }
  }, [currentFolderView, folder]);

  return (
    <>
      <ListItem
        onClick={handleFolderItemClick}
        selected={currentFolderView._id === folder._id}
        sx={{
          color: theme.palette.primary.main,
          borderRadius: ' 0px 50px 50px 0px',
          ' .MuiListItemSecondaryAction-root': {
            left: `${paddingLeft}px`,
            right: 'unset'
          },
          ' .MuiListItemIcon-root': {
            color: theme.palette.primary.main
          }
        }}
        secondaryAction={
          isLoading ? (
            <CircularProgress color="primary" size={18} />
          ) : (
            <Button
              onClick={(event) => handleExpand(event)}
              sx={{ minWidth: '30px', padding: '0px' }}
            >
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </Button>
          )
        }
        disablePadding
      >
        <ListItemButton
          role={undefined}
          dense
          sx={{
            paddingLeft: `${30 + paddingLeft}px`,
            paddingRight: '8px!important',
            borderRadius: ' 0px 50px 50px 0px'
          }}
        >
          <ListItemIcon sx={{ minWidth: 'unset' }}>
            {open ? (
              <FolderOpen
                sx={{
                  marginRight: '10px',
                  fontSize: '20px',
                  color: theme.palette.primary.main
                }}
              />
            ) : (
              renderFolderIcon(folder)
            )}
          </ListItemIcon>
          <ListItemText
            primary={folder.name}
            sx={{
              my: 0,
              textTransform: 'none',
              '& span': { fontSize: '16px' },
              whiteSpace: 'nowrap'
            }}
          />
        </ListItemButton>
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ ' .MuiCollapse-wrapperInner': { width: 'unset' } }}
      >
        {childrenTreeNodes.map((item) => {
          if (
            item.type === FILE_TYPES.FOLDER ||
            item.type === FILE_TYPES.CASE
          ) {
            return (
              <NavigationPaneFolder
                folder={item}
                key={item._id}
                setOpen={setOpen}
                paddingLeft={30 + paddingLeft}
                style={{ padding: '0px 0px 0px 0px' }}
              />
            );
          }
          return (
            <TreeFileItem
              file={item}
              key={item._id}
              paddingLeft={30 + paddingLeft}
              style={{ padding: '0px 0px 0px 0px' }}
            />
          );
        })}
      </Collapse>
    </>
  );
};
export default memo(NavigationPaneFolder);
